@import "../abstracts/variables";

.h1,.h2,.h3,.h4,.h5,.h6,
.text-body,
.text-body-sm,
.text-body-xs,
.text-overline {
  font-family: museo-sans,sans-serif;
  color: $black;
}

.text-caption {
  font-weight: 600;
  font-family: museo-sans,sans-serif;
}

.text-body,
.text-body-sm,
.text-body-xs {
  font-weight: 300;
}

.h1 {
  font-size: 50px;
  line-height: 60px;
}

.h2 {
  font-size: 38px;
  line-height: 46px;
}

.h3 {
  font-size: 28px;
  line-height: 34px;
}

.h4 {
  font-size: 21px;
  line-height: 25px;
}

.h5 {
  font-size: 16px;
  line-height: 19px;
}

.h6 {
  font-size: 12px;
  line-height: 14px;
}

.text-body {
  font-size: 16px;
  line-height: 22px;
}

.text-body-sm {
  font-size: 12px;
  line-height: 17px;
}

.text-caption {
  font-size: 10px;
  line-height: 12px;
}

.text-body-xs {
  font-size: 9px;
  line-height: 13px;
}

.text-overline {
  @extend .text-body-xs;
  font-weight: 400;
}
