// MEDIA QUERY MANAGER
/*

0 - 600px:          Phone
600px - 900px       Table Portrait
900px - 1200px      Table Landscape
[1200px - 1800px]   Desktop Normal Styles
1800px +            Big Desktop

ORDER: Base + Typography > Generar Layout + Grid > Page Layout > Components

*/

@import "./variables";
@import '../breakpoints';

@mixin respond($breakpoint) {

  @if $breakpoint == phone-xs {
    @media (max-width: 320px) {
      @content;
    }
  }
  // Phone
  @if $breakpoint == phone {
    @media (max-width: 600px) {
      @content;
    }
  }
  // Table small
  @if $breakpoint == tab-port-sm {
    @media (max-width: 768px) {
      @content;
    }
  }
  // Table Portrait
  @if $breakpoint == tab-port {
    @media (max-width: 990px) {
      @content;
    }
  }
  // Table Landscape
  @if $breakpoint == tab-land {
    @media (max-width: 1200px) {
      @content;
    }
  }
  // Big Desktop
  @if $breakpoint == big-desktop {
    @media (min-width: 1800px) {
      @content;
    }
  }
}

// Border White Mixin
@mixin sectionBorder($radius:4px) {
  border: 1px solid rgba(16, 18, 46, 0.16);
  border-radius: $radius;
}

@mixin supportColorForIE11 {
  // Fall for text-gradient not supported in IE11
  color: $secondary-color;
  -webkit-text-fill-color: $secondary-color; // fallback

  @supports (-webkit-background-clip: text) or (background-clip: text) {
    background-image: linear-gradient(135deg,
            $primary-color 0%,
            $secondary-color 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
  }
}

@mixin supportIE11 {
  // Provide your basic styles to support IE11
  @media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
    @content;
  }
}

@keyframes roll {
  from {
    transform: rotate(var(--start-deg, 0deg));
  }
  to {
    transform: rotate(var(--end-deg, 180deg));
  }
}

@mixin centerAbsolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin pseudoElement($display:block, $position:absolute) {
  width: 100%;
  height: 100%;
  position: $position;
  content: '';
  display: $display;
}

@mixin flexRowWrap($width:100%) {
  width: $width;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

@mixin welcomeWrapCenter($width:100%) {
  width: $width;
  position: relative;
  flex-wrap: wrap;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


@mixin welcomeModal() {
  width: 500px;
  padding: 60px 40px;
  text-align: center;
  background-color: white;
  border-radius: 4px;
  box-sizing: border-box;
}

@mixin pageWrap() {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $backgroud-color;
  height: fit-content;
}

@mixin pageContent($width:700px) {
  background-color: $white-color;
  width: 700px;
  height: fit-content;
  padding: 32px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-sizing: border-box;
}

@mixin formFieldError($width:100%) {
  display: block;
  width: $width;
  text-align: left;
  font-size: $small-font-size;
  color: #F2514D;
  line-height: 140%;
  margin-top: 8px;

  &:first-letter {
    text-transform: uppercase;
  }
}

@mixin dropZoneBefore() {
  content: ' ';
  display: block;
  position: absolute;
  border: 2px solid white;
  top: -3px;
  bottom: -3px;
  left: -3px;
  right: -3px;
  border-radius: 6px;
}

@mixin dropZoneActive() {
  background-color: $backgroud-color;
  border: 2px dashed rgba(39, 36, 233, 0.9);
  -webkit-box-shadow: 0px 0px 15px -5px rgba(34, 60, 80, 0.2) inset;
  -moz-box-shadow: 0px 0px 15px -5px rgba(34, 60, 80, 0.2) inset;
  box-shadow: 0px 0px 15px -5px rgba(34, 60, 80, 0.2) inset;

  &::before {
    display: none;
  }
}

@mixin sm {
  @media (min-width: $sm) { @content; }
}

@mixin md {
  @media (min-width: $md) { @content; }
}

@mixin lg {
  @media (min-width: $lg) { @content; }
}

@mixin xl {
  @media (min-width: $xl) { @content; }
}

@mixin xxl {
  @media (min-width: $xxl) { @content; }
}
