@import "./abstracts/variables";
@import "./base/typography";
@import "./utilities";
@import "./abstracts/mixins";

.container {
  max-width: 1200px;
  padding: 0 30px;
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;

  @include respond(tab-land) {
    max-width: 980px;
  }
}
