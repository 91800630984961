@import "./abstracts/variables";
@import "./base/typography";

.text-white {
  color: $white;
}
.text-black-60 {
  color: rgba(0, 0, 0, 0.6);
}

.text-success {
  color: $success;
}
.text-warning-dark {
  color: $warning-dark;
}
.text-error-dark {
  color: $error-dark;
}

.text-dark-purple {
  color: $dark-purple;
}
.text-dark-purple-60 {
  color: $dark-purple-60;
}
.text-dark-purple-80 {
  color: $dark-purple-80;
}

.text-violet-blue {
  color: $violet-blue;
}

.bg-white {
  background: $white;
}
.bg-success {
  background: $success;
}
.bg-success-light {
  background: $success-light;
}
.bg-warning {
  background: $warning;
}
.bg-warning-light {
  background: $warning-light;
}
.bg-error {
  background: $error;
}
.bg-error-light {
  background: $error-light;
}
.bg-violet-blue {
  background: $violet-blue
}
.bg-violet-blue-8 {
  background: $violet-blue-8
}
.bg-violet-blue-20 {
  background: $violet-blue-20;
}
.bg-violet-blue-40 {
  background: $violet-blue-40;
}

.border-violet-blue {
  border: 1px solid $violet-blue;
}
.border-violet-blue-20 {
  border: 1px solid $violet-blue-20;
}

.hidden {
  display: none;
}

.rounded-full {
  border-radius: 9999px;
}
.border-xs {
  border-radius: 4px;
}

.text-left {
  text-align: left;
}
