$default-font-size:16px; 
$small-font-size:12px;

$white-color:#fff;
$backgroud-color:#F4F4F4;
$blue-color:#3937C0;
$orange-color: #3937C0;

$default-transition:.7s ease;

$white: #FFF;
$black: #000;
$zinc: #F4F4F4;

$success: #198B1E;
$success-light: rgba(68, 255, 75, 0.2);
$warning: #F4B43F;
$warning-light: #FCF7E6;
$warning-dark: #744E09;
$error: #F44A3F;
$error-light: #FCE6E6;
$error-dark: #932E2E;

$dark-purple-20: rgba(16, 18, 45, 0.2);
$dark-purple-40: rgba(16, 18, 45, 0.4);
$dark-purple-60: rgba(16, 18, 45, 0.6);
$dark-purple-80: rgba(16, 18, 45, 0.8);
$dark-purple: #10122D;

$violet-blue-8: rgba(57, 55, 192, 0.08);
$violet-blue-20: rgba(57, 55, 192, 0.2);
$violet-blue-40: rgba(57, 55, 192, 0.4);
$violet-blue-60: rgba(57, 55, 192, 0.6);
$violet-blue-80: rgba(57, 55, 192, 0.8);
$violet-blue: #3937C0;

$cream-20: rgba(254, 241, 229, 0.2);
$cream-40: rgba(254, 241, 229, 0.4);
$cream-60: rgba(254, 241, 229, 0.6);
$cream-80: rgba(254, 241, 229, 0.8);
$cream: #FEF1E5;

$shadow-1: 0px 4px 20px 2px rgba(0, 0, 0, 0.06);

$bg-zinc: $zinc;
