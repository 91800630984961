@import "../../../styles/utilities";

.chipRoot {
  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: auto;
    background: transparent;
    border: none;
    border-radius: 6px;
    @extend .text-body-sm;

    &:disabled {
      pointer-events: none;
      opacity: 0.3;
    }

    &.chipFullWidth {
      width: 100%;
    }

    &.chipOutlined {
      @extend .border-violet-blue-20;
      &.chipSizeSmall {
        @extend .text-body-sm;
        @extend .text-dark-purple;
        padding: 4px 8px;
      }
      &.chipSizeMedium {
        @extend .h6;
        @extend .text-dark-purple;
      }
      &.chipSizeLarge {
        @extend .h5;
        @extend .text-dark-purple;
      }

      .chipStartIcon, .chipEndIcon {
        @extend .text-dark-purple;
      }
    }

    &.chipFilled {
      @extend .bg-violet-blue;
      &.chipSizeSmall {
        @extend .text-body-sm;
        @extend .text-white;
        padding: 6px 8px;
      }
      &.chipSizeMedium {
        @extend .text-body-sm;
        @extend .text-white;
      }
      &.chipSizeLarge {
        @extend .h5;
        @extend .text-white;
      }

      .chipStartIcon, .chipEndIcon {
        @extend .text-white;
      }
    }

    &.chipCustom {
      padding: 6px 8px;
      @extend .rounded-full;

      &.success, &.warning, &.error {
        @extend .text-caption;
      }

      &.success {
        @extend .bg-success-light;
        @extend .text-success;
      }

      &.warning {
        @extend .bg-warning-light;
        @extend .text-warning-dark;
      }

      &.error {
        @extend .bg-error-light;
        @extend .text-error-dark;
      }

      &.chipSizeSmall {
        min-height: 25px;
      }
    }
    
    .chipStartIcon, .chipEndIcon {
      display: flex;
      svg {
        fill: currentColor;
      }
    }

    .chipStartIcon {
      margin-right: 8px;
    }

    .chipEndIcon {
      margin-left: 8px;
    }

    &.chipSizeSmall {
      min-height: 29px;
      min-width: 29px;
    }

    &.chipSizeMedium {
      padding: 9px 12px 9px;
      min-height: 38px;
      min-width: 38px;
    }

    &.chipSizeLarge {
      padding: 14px 12px 14px;
      min-height: 48px;
      min-width: 48px;
    }
  }
}
